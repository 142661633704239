import { Container, Typography, Box } from '@mui/material'
import React from 'react'

const Contact = () => {
  return (
    <Box sx={{background:'#1D3557', marginTop:'5%'}}>

    
   <Container>
    <Typography sx={{
    
        paddingTop:'2%',
        fontFamily:'Roboto Slab',
        fontSize:{xs:'12px', md:'20px'},
        color:'#FFFFFF',
        lineHeight:{xs:'20px', md:'36px'},
        textAlign:'center',
        padding:'20px 0 5px 0',
    }}>
    Have questions about your webmail service? Need assistance with any issues? Reach 
    out to us at SUPPORT@INBOXTREE.ORG. We're here to help!
    </Typography>
   </Container>
   </Box>
  )
}

export default Contact
