import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { Snackbar, Alert, Button, TextField, Typography, Container, Paper } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[A-Za-z0-9._%+-]+$/, 'Invalid email prefix')
    .required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters'),
});

const Login = () => {
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const selectedDomain = '@mailread.org';

  const loginUser = async (values) => {
    try {
      const fullEmail = values.email + selectedDomain;
      const formData = new URLSearchParams();
      formData.append('Type', 'Login');
      formData.append('email', fullEmail);
      formData.append('password', values.password);

      const response = await fetch('https://mailread.org/api/ipn_register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      const text = await response.text();
      const cleanedText = text.replace(/<!--|-->/g, '');

      try {
        const responseData = JSON.parse(cleanedText);
        if (responseData.status === "ok") {
          sessionStorage.setItem('email', responseData.email);
          navigate('/Dashboard');
        } else {
          setError("Invalid Email or Password");
          setOpen(true);
        }
      } catch (jsonError) {
        setError('Failed to parse server response');
        setOpen(true);
      }

    } catch (error) {
      setError('Failed to login');
      setOpen(true);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    await loginUser(values);
    setSubmitting(false);
  };

  return (
    <>
    <Navbar />
   <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" component="h1" align="center">
          Login
        </Typography>

        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" onClose={() => setOpen(false)}>
            {error}
          </Alert>
        </Snackbar>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field name="email">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                      endAdornment: <span>{selectedDomain}</span>,
                    }}
                  />
                )}
              </Field>

              <Field name="password">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>

              <Button sx={{
                background:'#FF7F11',
                color:'#ffff',
                borderRadius:'10px 0px 10px 0px',
                padding:'7px 34px'
              }} type="submit"   disabled={isSubmitting}>
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>

    
    </Container>
    <Footer />
    </>
 
  );
};

export default Login;
