import React, { useEffect, useState } from 'react';
import Logo from "../../assets/images/Logo2.png";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invoices = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState([]);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [notifiedItems, setNotifiedItems] = useState({});

    useEffect(() => {
        const now = new Date();
        const formattedDate = now.toLocaleString('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }, []);

    useEffect(() => {
        const apiKey = sessionStorage.getItem('email');
        const fetchData = () => {
            axios.post(`https://mailread.org/api/ipn.php?user=${apiKey}`)
                .then(response => {
                    setResponseData(response.data);
                    setIsLoading(false);
                    checkForExpirations(response.data);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error("Error fetching data", error);
                });
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [notifiedItems]);

    const checkForExpirations = (data) => {
        const updatedNotifiedItems = { ...notifiedItems };

        data.forEach(item => {
            const now = new Date();
            const expirationDate = new Date(item.date);
            const timeDifference = expirationDate - now;
            const daysDifference = timeDifference / (1000 * 3600 * 24);

            if (daysDifference <= 7 && daysDifference > 0) {
                if (!updatedNotifiedItems[item.id] || !updatedNotifiedItems[item.id].thirtyDaysBefore) {
                    toast.info(`Your plan with ID ${item.id} will expire in ${Math.ceil(daysDifference)} days!`);
                    updatedNotifiedItems[item.id] = {
                        ...updatedNotifiedItems[item.id],
                        thirtyDaysBefore: true
                    };
                }
            }

            if (daysDifference <= 0 && item.status !== 'EXPIRED') {
                if (!updatedNotifiedItems[item.id] || !updatedNotifiedItems[item.id].expired) {
                    toast.error(`Your plan with ID ${item.id} has expired!`);
                    updatedNotifiedItems[item.id] = {
                        ...updatedNotifiedItems[item.id],
                        expired: true
                    };
                }
            }
        });

        setNotifiedItems(updatedNotifiedItems);
    };

    const hasSecondActivePlan = () => {
        const activePlans = responseData.filter(item => item.status === 'ACTIVE');
        return activePlans.length > 1;
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
    };

    const hasActiveSubscription = () => {
        return responseData.some(item => item.status === 'ACTIVE');
    };
    useEffect(() => {
        const Emails = sessionStorage.getItem('email');
      
        if (!Emails) {
            navigate("/Login");
        } else {
            setIsLoggedIn(true);
            setEmail(Emails);
        }
    }, []);

    const handleSignOut = () => {
        sessionStorage.removeItem('email');
        navigate('/');
    };

    const viewInvoice = (item) => {
        navigate(`/invoiceDetails/${item.id}`, { state: { item } });
    };

    const renderInvoices = () => {
        if (isLoading) {
            return (
                <div className="skeleton-loader">
                    <Skeleton height={40} count={5} />
                </div>
            );
        } else if (Array.isArray(responseData) && responseData.length > 0) {
            const filteredData = responseData.filter((item) =>
                item.id.toLowerCase().includes(searchQuery) ||
                `$${item.amount}`.toLowerCase().includes(searchQuery) ||
                item.status.toLowerCase().includes(searchQuery)
            );

            const hasActive = hasActiveSubscription();

            return (
                <table>
                    <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Plan</th>
                            <th>Price</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>Monthly</td>
                                <td>${item.amount}</td>
                                <td>{item.date && new Date(item.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
                                <td>{item.status === 'ACTIVE' ? 'PAID' : item.status}</td>
                                <td>
                                    <Button
                                        sx={{
                                            backgroundColor: 'orange',
                                            color: 'white',
                                            '&:hover': {
                                                background: '#FF7F11', 
                                            },
                                        }}
                                        variant='contained'
                                        onClick={() => viewInvoice(item)}
                                    >
                                        Invoice
                                    </Button>
                                    {!(item.status === 'CANCELLED' || item.status === 'SUSPENDED' || new Date(item.date) > new Date(new Date().setDate(new Date().getDate() + 7)) || hasActive) && (
                                        <form
                                            action="https://www.sandbox.paypal.com/cgi-bin/webscr"
                                            method="post"
                                        >
                                            <input type="hidden" name="business" value="sb-e47uc430331287@business.example.com" />
                                            <input type="hidden" name="cmd" value="_xclick-subscriptions" />
                                            <input type="hidden" name="a3" value={item.amount} />
                                            <input type="hidden" name="p3" value="1" />
                                            <input type="hidden" name="t3" value="M" />
                                            <input type="hidden" name="src" value="1" />
                                            <input type="hidden" name="sra" value="0" />
                                            <input type="hidden" name="item_name" value="Monthly Subscription" />
                                            <input type="hidden" name="item_number" value={sessionStorage.getItem('email')} />
                                            <input type="hidden" name="no_note" value="1" />
                                            <input type="hidden" name="currency_code" value="USD" />
                                            <input type="hidden" name="return" value="https://mailread.org/Dashboard" />
                                            <input type="hidden" name="cancel_return" value="https://mailread.org/Dashboard" />
                                            <input type="hidden" name="notify_url" value="https://mailread.org/api/ipn.php" />
    
                                            <Button
                                                type="submit" 
                                                variant="contained" 
                                                sx={{
                                                    background: 'linear-gradient(to right, #26658c, #984042)',
                                                    color: 'white', marginLeft:'20px',
                                                    '&:hover': {
                                                        background: 'linear-gradient(to right, #984042, #26658c)', 
                                                    },
                                                }}
                                            >
                                                RENEW
                                            </Button>
                                        </form>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className='no-data'>
                    No invoices found. It seems you haven't made any purchases yet. <br />
                    Please consider purchasing a plan to access your invoices.
                </div>
            );
        }
    };

    return (
        <div>
            {isLoggedIn && (
                <section className='dashboard-contain'>
                    <div className="container-fluid">
                        <div className="row flex-nowrap">
                            <div className="desh-main col-auto col-md-3 col-xl-2 px-sm-2 px-1">
                                <div className="d-flex flex-column align-items-md-center align-items-sm-start pt-2 text-white min-vh-100">
                                    <Link to="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                                        <span className="fs-5 d-none d-sm-inline"><img src={Logo} className="" width="20px" alt=""/></span>
                                    </Link>
                                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                                        <li>
                                            <Link to="/Dashboard" className="nav-link px-0 align-middle">
                                                <i className="fa fa-home" aria-hidden="true"></i> <span className="d-none d-sm-inline">Dashboard</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Invoices" className="nav-link px-0 align-middle">
                                                <i className="fa fa-file-text" aria-hidden="true"></i><span className=" ms-2 d-none d-sm-inline">Invoices</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="https://mailread.org/mail/" target='_blank' className="nav-link px-0 align-middle">
                                                <i className="fa fa-file-text" aria-hidden="true"></i><span className=" ms-2 d-none d-sm-inline">Webmail</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="dropdown pb-4 signout-dashbaord">
                                        <button className='signout_button' onClick={handleSignOut}>
                                            <i className="fa fa-sign-out cursor-pointer" aria-hidden="true"></i> Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col py-3">
                                <div className="dashboard">
                                    <div className="dashboard-main">
                                        <div className="">
                                            <h4 className="fw-bold">Invoices</h4>
                                            <p className='invoice_para'>Download available invoice and Let’s finish your unpaid orders.</p>
                                        </div>
                                        <div className="">
                                            <input
                                                className="server-search"
                                                type="search"
                                                placeholder="Search Order Number"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-main">
                                        {renderInvoices()}
                                    </div>
                                    <div className="dash-footer">
                                        <p>© {currentYear}. InboxTree. All Rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <ToastContainer />
        </div>
    );
};

export default Invoices;
