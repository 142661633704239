import { AppBar, Container, Typography,Grid, Box } from '@mui/material'
import React from 'react';
import logo from '../assets/images/Logo2.png';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <>
        <Container sx={{marginTop:'5%'}}>
            <img src={logo}></img>
            <Typography sx={{
                width:{xs:'100%',md:'833px'},
                margin:'auto',
                paddingTop:'4%',
                fontSize:{xs:'15px', md:'19px'},
                fontFamily:'Roboto Slab',
                color:'#000000',
                textAlign:'center',
                lineHeight:{xs:'18px', md:'36px'}
            }}>
            Welcome to InboxTree, where we're redefining the way you think about email. Our platform 
            empowers you to transform your everyday emails into a valuable source of income.
            </Typography>


         <Grid container sx={{paddingTop:'4%'}}>
         <Grid item md={12}>
         <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:'30px'}}> 
            <Typography sx={{
                fontFamily:'Roboto Slab',
                fontSize:{xs:'12px', md:'18px'},
                color:'#1D3557',
                
            }}>HOME</Typography>
           <Link to='features' smooth={true} duration={500}>  <Typography sx={{
                fontFamily:'Roboto Slab',
              fontSize:{xs:'12px', md:'18px'},
                color:'#1D3557',
                cursor:'pointer'
                
            }}>FEATURES</Typography></Link>
       <Link to='about' smooth={true} duration={500}> <Typography sx={{
                fontFamily:'Roboto Slab',
                fontSize:{xs:'12px', md:'18px'},
                color:'#1D3557',
                 cursor:'pointer'
                
            }}>ABOUT US</Typography></Link>    

            <Link to='how' smooth={true} duration={500}> <Typography sx={{
                fontFamily:'Roboto Slab',
               fontSize:{xs:'12px', md:'18px'},
                color:'#1D3557',
                 cursor:'pointer'
                
            }}> HOW TO USE</Typography></Link>

           <Link to='pricing' smooth={true} duration={500}> <Typography sx={{
                fontFamily:'Roboto Slab',
               fontSize:{xs:'12px',sm:'16px', md:'18px'},
                color:'#1D3557',
                 cursor:'pointer'
                
            }}> PRICING</Typography>
            </Link>
           </Box>
         </Grid>


      

         </Grid>
           
               
          
        </Container>
        <Box sx={{background:'#1D3557', marginTop:'5%', height:'50px', width:'100%', borderRadius:'8px'}}>
         <Typography sx={{
            paddingTop:'2%',
                fontFamily:'Roboto Slab',
                fontSize:'15px',
                color:'#FFFFFF',
                
            }}>
       © 2024 Copyright:All rights reserved.
         </Typography>
         </Box>
    </>
  )
}

export default Footer
