import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import html2pdf from 'html2pdf.js';
import Logo from "../../assets/images/Logo3.svg";

const InvoiceDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const [invoice, setInvoice] = useState(location.state?.item);
    const invoiceRef = useRef();
    const buttonRef = useRef();
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        if (!invoice) {
            axios.get(`/api/invoices/${id}`)
                .then(response => {
                    setInvoice(response.data);
                    
                })
                .catch(error => {
                    console.error('There was an error fetching the invoice details!', error);
                });
        }
    }, [id, invoice]);

    useEffect(() => {
        const now = new Date();
        const formattedDate = now.toLocaleString('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
        setCurrentDate(formattedDate);
    }, []);

    const generatePdf = () => {
        const element = invoiceRef.current;
        const button = buttonRef.current;
        
        // Hide the button
        button.style.display = 'none';

        html2pdf().from(element).set({
            margin: 1,
            filename: `invoice_${invoice.id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        }).save().then(() => {
            // Show the button after PDF is generated
            button.style.display = 'block';
        });
    };

    if (!invoice) {
        return <div>Loading...</div>;
    }
    const apiKey = sessionStorage.getItem('email');
     //console.log(apiKey,"This is email");

     //console.log(invoice);

    return (
        <div ref={invoiceRef}>
            {/* <h1>Invoice Details</h1>
            <p>Invoice ID: {invoice.id}</p>
            <p>Customer Name: {invoice.email}</p>
            <p>Subscription Date: {new Date(invoice.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
            <p>Amount: {`$${invoice.amount}`}</p>
            <p>Status: {invoice.status}</p>
            <Button ref={buttonRef} variant='contained' onClick={generatePdf}>Print or save Pdf</Button> */}

            <table style={{ width: '800px', margin:'auto', maxWidth:'100%' }}>
            <tbody>
                <tr>
                    <td>
                        <table style={{ width: '100%', borderBottom: '2px solid #ddd' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px', width: '50%' }}><img src={Logo} style={{ width: '70px' }} alt="Logo"/></td>
                                    <td style={{ padding: '0px', width: '50%', fontSize: '24px', textAlign: 'right' }}>InboxTree</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style={{ width: '100%', borderBottom: '0px solid #ddd', paddingTop: '25px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px', width: '50%' }}></td>
                                    <td style={{ padding: '0px', width: '50%', fontSize: '24px', textAlign: 'right' }}>
                                        <button style={{ background:'rgb(77, 158, 227)', padding: '8px 20px', fontSize: '18px', border: '0px', borderRadius: '50px', color: '#fff', textTransform: 'uppercase', fontWeight: '700' }}>
                                            {invoice.status}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style={{ width: '100%', borderBottom: '0px solid #ddd', paddingTop: '25px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px', width: '50%' }}>
                                        <h4 style={{ fontSize: '24px', margin: '0px' }}>INVOICED NUMBER:</h4>
                                        <p style={{ fontSize: '22px', color: '#696969', margin: '10px 0px 20px' }}>{invoice.id}</p>
                                        <h4 style={{ fontSize: '24px', margin: '0px' }}>INVOICED TO:</h4>
                                        <p style={{ fontSize: '22px', color: '#696969', margin: '10px 0px 20px' }}>{apiKey}</p>
                                    </td>
                                    <td style={{ padding: '0px', width: '50%', textAlign: 'right' }}>
                                        <h4 style={{ fontSize: '24px', margin: '0px' }}>Renew Date</h4>
                                        {invoice.date ? (
                                             <p style={{ fontSize: '22px', color: '#696969', margin: '10px 0px 20px' }}>
                                                 {new Date(invoice.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                             </p>
                                        ):null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style={{ width: '100%', borderBottom: '0px solid #ddd', paddingTop: '25px' }}>
                            <tbody>
                                <tr>
                                    <th style={{ padding: '7px 0px', width: '50%', fontSize: '18px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                        Items
                                    </th>
                                    <th style={{ padding: '7px 0px', width: '50%', textAlign: 'right', borderBottom: '1px solid #000' }}>
                                        Price
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 0px', width: '50%', fontSize: '18px', borderBottom: '1px solid #000', textAlign: 'left' }}>
                                        Subscription (1 Month)
                                    </td>
                                    <td style={{ padding: '7px 0px', width: '50%', textAlign: 'right', borderBottom: '1px solid #000' }}>
                                        {`$${invoice.amount}`}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'right' }}>
                                        <table style={{ width: '340px', marginTop: '10px', maxWidth: '100%', float: 'right' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ padding: '7px 0px', width: '40%', fontSize: '22px', textAlign: 'left' }}>Amount</th>
                                                    <th style={{ padding: '7px 0px', width: '60%', fontSize: '22px', textAlign: 'right' }}>{`$${invoice.amount}`}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style={{ width: '100%', paddingTop: '50px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px', width: '50%', textAlign: 'left', fontSize: '24px' }}>Payment Method: PayPal</td>
                                    <td style={{ padding: '0px', width: '50%', fontSize: '24px', textAlign: 'right' }}>
                                        <button style={{ background: '#4d9ee3', padding: '8px 20px', fontSize: '18px', border: '0px', borderRadius: '50px', color: '#fff', textTransform: 'uppercase', fontWeight: '700',fontFamily:'Libre Baskerville'}} onClick={generatePdf} ref={buttonRef}>
                                            Print or Save PDF
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style={{ width: '100%', marginTop: '30px' }}>
                            <tbody>
                                <tr>
                                    {/* <td style={{ padding: '0px', width: '50%' }}>
                                        <img src={Logo} style={{ width: '70px' }} alt="Logo"/><span style={{ fontSize: '22px', color: '#696969', display: 'block', width: '100%' }}>{invoice.email}</span>
                                    </td> */}
                                    <td style={{ padding: '0px', width: '50%', textAlign: 'right', fontSize: '22px', color: '#696969' }}>Generated on: {currentDate}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
           
        </div>
    );
};

export default InvoiceDetails;
